@import '../../../../Styles/theme.scss';
@import '../../../../Styles/variables.scss';


.item {
    align-items: center;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    width: 30px;
  }
  .page {
    margin-right: 20px;
  }
  .disabled-page {
    color: #808e9b;
  }
  
  .active {
    border: solid 1px $primary-color-1;
    background-color: $primary-color-1;
    border-radius: 50%;
  }
  
  .break-me {
    color: #000
  }
  
  .next {
    font-weight: bold;
    color: $primary-color-1;
    margin-left: 5;
  }
  
  .pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
  }
  
  .pagination-page {
    font-weight: 700;

  }
  
  .previous {
    font-weight: bold;
    color: $primary-color-1;
    margin-right: 5px;

   }

   .disabled-page{
    color: #808e9b;

   }