@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.disclaimer-row {
  justify-content: center;
}

.disclaimer-row-text {
  width: 100%;
}

.reimbursement-web-image {
  display: none;
}
.reimbursement-mobile-image {
  display: inherit;
}
.datepicker-input {
  background-color: #edfdff;
  border-radius: 3px;
  margin-bottom: 0px;
  box-shadow: inset 0px 1px 3px;
  height: 50px;
  border: 2px solid transparent;
  max-width: 100%;
}

.datepicker-error {
  background-color: #edfdff;
  border-radius: 3px;
  margin-bottom: 0px;
  min-height: 50px;
  border: 0px;
  border: 2px solid red;
  height: 50px;
}

.form-container {
  font-family: 'Raleway';
  margin: 0px 15px 25px 15px;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.reimbursement-image {
  display: none;
  bottom: 0px;
  max-height: 73%;
}

.reimbursement-form-container {
  display: flex;
  flex-direction: column;
}

/* .form-container {
  margin: 2%;
  position: relative;
}
 */
.reimbursement-form-row {
  display: flex;
  flex-direction: column;
}

.reimbursement-text-input {
  max-width: 100%;
}

.instructions-text {
  margin-bottom: 10px;
  align-items: center;
}

.card-header {
  cursor: pointer;
}

.reimbursement-signature {
  width: 100%;
  margin: 25px 0px;
}

.reimbursement-title {
  font-size: 8vw;
}

.reimbursement-date-picker {
  width: 100%;
  padding-left: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: #495057;
}
.reimbursement-city {
  width: 58%;
}
.reimbursement-state {
  width: 40%;
}
.reimbursement-zip {
  width: 100%;
}
.reimbursement-checkbox-row {
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  width: auto;
}

.reimbursement-other {
  width: 100%;
  margin-bottom: 25px;
}

.reimbursement-count-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 1600px) {
  .form-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media screen and (min-width: 1000px) {
  .reimbursement-web-image {
    display: inherit;
  }
  .reimbursement-mobile-image {
    display: none;
  }
  .reimbursement-date-picker {
    width: 96%;
  }
  .form-container {
    box-shadow: 1px 3px 10px 2px lightgray;
    max-width: 1600px;
    padding: 50px;
    margin-left: 50px;
    margin-right: 50px;
    //margin: 25px 50px 25px 50px;
  }
  .reimbursement-image {
    display: block;
  }

  .reimbursement-form-container {
    flex-direction: row;
  }

  .reimbursement-form-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .reimbursement-text-input {
    width: 49%;
  }

  .instructions-text {
    display: none !important;
  }

  .reimbursement-signature {
    width: 49%;
  }
  .reimbursement-title {
    font-size: 60px;
  }
  .reimbursement-city {
    width: 38%;
  }
  .reimbursement-state {
    width: 20%;
  }
  .reimbursement-zip {
    width: 38%;
  }
  .reimbursement-checkbox-row {
    width: max-content;
  }
  .reimbursement-other {
    width: 49%;
  }

  .reimbursement-count-row {
    flex-direction: row;
  }

  .reimbursement-count-text {
    max-width: 45%;
  }
  .reimbursement-count {
    max-width: 49%;
  }
  .disclaimer-row-text {
    width: 50%;
  }
}
