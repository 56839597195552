@import '../../Styles/variables.scss';

.appointment-header {
  font-weight: bold;
  font-family: Raleway-Bold;
  margin-bottom: 0;
  text-align: center;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RSPBprogressBar {
  //height: 4px !important;
}

.indexedStep.accomplished {
  background-color: $primary-color-1;
}

.appointment-subheader {
  font-weight: bold;
  font-family: Raleway-Bold;
  margin-bottom: 0;
  color: $primary-color-1;
  text-align: center;
}

.appointment-form {
  text-align: left;
}

.appointment-banner-image {
  max-height: 600px;
  min-width: 100%;
}

.appointment-container {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 25px;
}

.appointment-form-button {
  background-color: $primary-color-1;
  font-family: Raleway-Semi-Bold;
  padding-top: 10px;
  width: 100%;
  padding: 10px 0px;
}

@media screen and (min-width: 1200px) {
  .appointment-header {
    font-weight: bold;
    font-size: 60px;
    font-family: Raleway-Bold;
    margin-bottom: 0;
      text-align: left;
  }

  .appointment-subheader {
    font-weight: bold;
    font-size: 60px;
    font-family: Raleway-Bold;
    margin-bottom: 0;
    color: $primary-color-1;
    text-align: left;
  }

  .appointment-form {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 30%;
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .appointment-header {
    font-size: 60px;
  }

  .appointment-subheader {
    font-size: 60px;
  }

  .appointment-banner-image {
    margin-top: 0px;
    min-width: 0px;
    max-height: 800px;
  }

  .appointment-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
  }
}
