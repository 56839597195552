@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';
@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar {
  left: -350px;
  width: auto;
  min-height: 100%;
  height: auto;
  position: fixed;
}

.toggled {
  top: 71px;
  left: 0px;
  position: fixed;
}

.pro-sidebar > .pro-sidebar-inner {
  background: $primary-color-1;
  border-top-left-radius: 0px;
  border-radius: 0px 0px 4px 0px;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background: $primary-color-1;
}

.profile-home-dropdown {
  width: 100%;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 3px;
}

.profile-circle {
  background-color: $neutral-color-1;
  height: 150px;
  width: 150px;
  border-radius: 100px;
  margin-bottom: 15px;
}

.profile-content-container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  box-shadow: none !important;
  border: none !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}

.profile-menu {
  background-color: $primary-color-1;
  color: $neutral-color-1;
  min-height: 700px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 25%;
}

.profile-content {
  background-color: $neutral-color-1;
  color: $neutral-color-1;
  width: 100%;
  border-radius: 4px;
  padding-left: 2%;
  padding-right: 2%;
}
.profile-page-container {
  margin: 2% 0%;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

.profile-mobile-menu {
  background-color: $primary-color-1;
  min-height: 100vh;
  margin-top: -2%;
}

@media screen and (min-width: $small) {
  .profile-menu {
    width: 30%;
  }

  .profile-content {
    width: 100%;
    margin-left: 1%;
  }

  .profile-content-container {
    min-height: 800px;
    margin-top: 0px;
  }
}

@media screen and (min-width: $medium) {
  .profile-home-dropdown {
    width: 350px;
  }
  .profile-menu {
    display: flex;
  }

  .profile-content {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #e0e0e0;
    width: 100%;
  }
  .profile-content {
    padding-left: 40px;
    padding-top: 20px;
    padding-right: 40px;
  }
  .pro-sidebar {
    position: inherit;
  }
  .pro-sidebar > .pro-sidebar-inner {
    border-radius: 4px 4px 4px 4px;
  }
}

@media screen and (min-width: 1300px) {
  .profile-menu {
    width: 20%;
  }

  .profile-content {
    border-radius: 4px;
    margin-left: 1%;
    width: 100%;
  }
}
