@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';


.glucose-video-column {
  width: 100%;
  margin: 0%;
}

.add-caregiver-image {
  margin-bottom: 15px;
  cursor: pointer;
}

@media screen and (min-width: $medium) {
  .glucose-video-column {
    width: 35%;
    margin: 0% 5% 0% 5%;
  }
}
