@import '../../../Styles/variables.scss';

.carousel-header {
  font-size: 6vw;
}

.welcome-info-container {
  position: relative;
  justify-content: flex-end;
  height: 100vh;
  width: 100%;
}

.carousel-slider {
  box-shadow: 'none';
  border-radius: 15px;
  @media screen and (min-width: 768px) {
    height: 220px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.welcome-form {
  position: absolute;
  top: 5%;
  left: 4%;
  width: 90%;
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
}

.welcome-banner-image {
  max-height: 300px !important;
  min-width: 100%;
}

.welcome-image-column {
  min-width: 100%;
}

@media screen and (min-width: 650px) {
  .welcome-column {
    //position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 10%;
    left: 5%;
    width: 40%;
    text-align: left;
  }
}

@media screen and (min-width: 1050px) {
  .welcome-column {
    left: 10%;
    width: 35%;
  }
}

@media screen and (min-width: 1300px) {
  .welcome-form {
    padding: 0px;
    background-color: transparent;
  }
}

@media screen and (min-width: 1100px) {
  .carousel-header {
    font-size: 2rem;
  }
  .welcome-info-container {
    margin-top: 0px;
    display: flex;
  }
  .welcome-banner-image {
    max-height: 600px !important;
    margin-top: 0px;
    min-width: 0px;
  }
  .welcome-form {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 30%;
    text-align: left;
  }

  .welcome-messages-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
  }

  .welcome-message-item {
    width: 45%;
    margin-bottom: 100;
  }
}
