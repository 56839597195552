@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.resources-container {
  margin: 65px 8% 10% 8%;
  text-align: center;
  padding-top: 25px;
  justify-content: center;
}

.card-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.resources-header {
  font-family: 'Raleway-Bold';
}
@media screen and (min-width: $small) {
}

@media screen and (min-width: $medium) {
  .card-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .resources-container {
    margin-top: 0px;
  }
}
