@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

/* .not-found-container {
  margin: 4%;
} */

.not-found-subtitle {
  font-size: 7vw;
}

.not-found-header-text {
  font-weight: bold;
  font-family: Raleway-Bold;
  font-size: 9vw;
  margin-bottom: 0;
}

.not-found-image {
  max-height: 250px;
  min-width: 100%;
}

@media screen and (min-width: $small) {

  .not-found-form {
    position: absolute;
    top: 10%;
    left: 5%;
    width: 40%;
    text-align: left;
  }
  .not-found-subtitle {
    font-size: 2vw;
  }
  .not-found-header-text {
    font-size: 3.2vw;
  }
  .not-found-image {
    margin-top: 0px;
    min-width: 0px;
    max-height: 800px;
  }
}

@media screen and (min-width: 500px) {
  .not-found-image {
    margin-top: 0px;
    max-height: 500px;
    min-width: 0px;
  }
  /* .not-found-container {
    margin: 2% 0%;
  } */
}

@media screen and (min-width: 1200px) {
  .not-found-image {
    margin-top: 0px;
    min-width: 0px;
    max-height: 800px;
  }
}
