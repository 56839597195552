@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.login-input {
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
}

.register-form {
  text-align: left;
}

.register-container {
  margin-top: 75px;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 25px;
}

.register-banner-image {
  max-height: 900px;
  min-width: 100%;
}

.forgot-header-text {
  text-align: center;
  font-weight: bold;
  font-family: Raleway-Bold;
  font-size: 9vw;
  margin-bottom: 0;
}
.register-form-fields {
  max-width: 100%;
}
@media screen and (min-width: $small) {
  .forgot-header-text {
    font-size: 3.2vw;
    text-align: left;
  }
  .register-banner-image {
    margin-top: 0px;
    min-width: 0px;
  }
  .register-form {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 40%;
    text-align: left;
  }
  .register-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    margin-top: 0px;
  }
}
