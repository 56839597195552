@import 'Styles/theme.scss';
@import 'Styles/variables.scss';

.careplan-header-container {
  max-width: 100%;
  margin-top: 25px;
  border-radius: 20px;
  text-align: center;
}

.header-image {
  display: none;
  min-width: 100%;
  border-radius: 20px;
  height: 215px;
  text-align: center;
  margin-bottom: 10px;
}

@media screen and (min-width: $medium) {
  .header-image {
    display: block;
  }
  .header-image {
    min-width: 300px;
  }
  .careplan-header-container {
    min-width: 300px;
  }
}
