@import '../../../../Styles/variables.scss';
@import '../../../../Styles/theme.scss';

.communication-input {
  width: 100%;
  background-color: #edfdff !important;
}

.communication-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.input-spacer {
  min-width: 25px;
  display: none;
}

.communication-save-button {
  background-color: $primary-color-1;
  font-family: 'Raleway-Semi-Bold';
  padding-top: 10px;
  margin-top: 10px;
  border-radius: 4px;
  padding-bottom: 10px;
  width: 100%;
}

@media screen and (min-width: 1050px) {
  .communication-input {
    width: 325px;
  }
  .input-spacer {
    display: block;
  }
  .communication-row {
    flex-direction: row;
  }
}

@media screen and (min-width: $small) {
  .communication-save-button {
    width: 200px;
  }
}
