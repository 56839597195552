@import '../../../../Styles/theme.scss';
$small: 650px;
$medium: 900px;

.menu-button {
  display: block;
}

.header-title {
  font-family: 'Raleway-Bold';
  font-size: 25px;
  color: #00677f;
  font-weight: 600px;
}

.header-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.menu-button {
  color: #00677f;
  margin-left: 5;
}

@media screen and (min-width: $small) {
  .menu-button {
    display: none;
  }
  .header-title {
    font-size: 40px;
  }
}
