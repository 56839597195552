@import '../../../Styles/variables.scss';
@import '../../../Styles/theme.scss';

.horizontal-slider {
  align-items: center;
  display: flex;
}
.slider-track {
  background-color: lightgray;
  height: 15px;
}

.slider-track-0 {
  background-color: $primary-color-1;
  border-radius: 6px;
}

.slider-track-1 {
  margin-left: 10px;
  border-radius: 6px;
}

.slider-thumb {
  background-color: $primary-color-1;
  cursor: pointer;
  color: $neutral-color-1;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 15px;
  height: 30px;
  width: 30px;
}

.rapid-score-container {
  width: 100%;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rapid-key-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: $medium) {
  .rapid-score-container {
    width: 100px;
  }
  .rapid-key-row {
    flex-direction: row;
  }
}
