@import '../../Styles/variables.scss';
@import '../../Styles/theme.scss';

.info-header {
  background-color: $primary-color-1;
  color: $neutral-color-1;
}

.ctl-left-column {
  width: 100%;
}

.ctl-right-column {
  width: 100%;
  align-items: center;
}

.why {
  max-width: 400px;
  margin-top: 50px;
  margin-right: 0px;
  margin-left: 0px;
}

.ctl-apply-button {
  font-family: 'Raleway-Semi-Bold';
  background-color: #00677f;
  padding-top: 10px;
  width: 100%;
  border: 0px;
  padding-bottom: 10px;
  margin-right: 15px;
  margin-top: 20px;
}
.how-it-works {
  font-family: 'Raleway-Semi-Bold';
  background-color: #fff;
  border: 1px solid #00677f;
  display: flex;
  width: 193px;
  margin-right: 15px;
  margin-top: 20px;

  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.apply-now {
  font-family: 'Raleway-Semi-Bold';
  background-color: #00677f;
  border: 1px solid #00677f;
  display: flex;
  width: 176px;
  margin-right: 15px;
  margin-top: 20px;
  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ctl-calculate-button {
  margin-top: 15px;
  font-family: 'Raleway-Semi-Bold';
  background-color: #00677f;
  padding-top: 10px;
  width: 100%;
  border: 0px;
  padding-bottom: 10px;
}

.ctl-input {
  min-width: 100%;
}

.ctl-result {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.program-req-card {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 5px;
  margin: 10px;
  max-width: 550px;
  margin-top: 50px;
}
.health-coach-image {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-top: 40px;
  height: auto;
}
.accordion-card {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  padding: 15px 5px 10px;
  border-radius: 5px;
  margin-top: 20px;
  max-width: 400px;
  cursor: pointer;
}

.app-and-fruit {
  margin: 0px auto;
  max-width: 95%;
}

.logo {
  max-width: 300px;
  margin: 50px auto 15px;
}

.accordion-title {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: auto;
}

@media screen and (min-width: 1200px) {
  .ctl-left-column {
    max-width: 631px;
    margin-right: auto;
  }

  .ctl-right-column {
    width: 50%;
    align-items: center;
  }
  .ctl-apply-button {
    width: 50%;
    margin-bottom: 10px;
  }

  .ctl-input {
    min-width: 0px;
  }

  .ctl-calculate-button {
    width: 25%;
  }

  .ctl-result {
    align-items: flex-start;
  }

  .why {
    max-width: 550px;
    margin-top: 50px;
    margin-right: 30px;
    margin-left: auto;
  }

  .program-req-card {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 5px;
    margin: 50px 30px 5px auto;
    max-width: 550px;
  }
  .health-coach-image {
    margin-top: 50px;
    margin-right: 0px;
    margin-left: auto;
    max-width: 590px;
    max-height: 469px;
  }

  .accordion-card {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    padding: 15px 5px 10px;
    border-radius: 5px;
    margin: 20px 20px 5px 0px;
    max-width: 550px;
    cursor: pointer;
  }

  .app-and-fruit {
    margin: 55px auto auto 0px;
    max-width: 548;
  }

  .logo {
    max-width: 800px;
    height: 120px;
    margin: 60px auto 0px 0px;
  }
  .accordion-title {
    margin-bottom: auto;
    margin-top: auto;
    margin-right: auto;
  }
}
