@import '../../../../../../Styles/theme.scss';
@import '../../../../../../Styles/variables.scss';

.MuiDataGrid-root {
  border: none;
}
.dashboard-grid-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  padding: 10px;
  border-radius: 10px;
}
