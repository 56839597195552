@import '../../../../Styles/variables.scss';
@import '../../../../Styles/theme.scss';

.resource-card {
  height: 200px;
  width: 100%;
  margin: auto;
  background-color: #f2f2f2;
  border: 1px solid #828282;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media screen and (min-width: $small) {
  .resource-card {
    width: 300px;
  }
}
