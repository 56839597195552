@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

#collapsible-nav-dropdown {
  justify-content: center;
}

.nav-link {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.link-text {
  color: $neutral-color-1 !important;
  //font-size: 1.5vw;
  font-weight: 500;
  margin-right: 0px;
  text-align: center;
}

.icon-container {
  display: none !important;
  width: 8rem;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  display: flex;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1%;
  margin-right: 1%;
}

.login-out-btn {
  margin-right: 1rem;
  border-radius: 0px;
  width: 50%;
  border: 1px solid $neutral-color-1;
  background-color: #7fb3bf;
}

.appointment-btn {
  margin-right: 1rem;
  border-radius: 0px;
  border: 1px solid $neutral-color-1 !important;
  background-color: $primary-color-1;
}

.appointment-btn:active {
  background-color: $primary-color-1 !important;
}

.appointment-btn:focus {
  background-color: $primary-color-1 !important;
}

.login-out-btn:active {
  background-color: $primary-color-1 !important;
}

.top-row-btn {
  margin-right: 1rem;
  border: 0px;
  background-color: #425563;
}

.top-row-btn:active {
  background-color: $primary-color-1 !important;
}

.mobile-hidden {
  display: none;
}

@media screen and (min-width: $small) {
  .icon-container {
    display: flex !important;
  }

  .mobile-hidden {
    display: flex;
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .dropdown-item {
    font-size: 0.8rem;
    padding-left: 10px;
  }
  .dropdown-item:hover {
    background-color: $primary-color-1;
    color: white !important;
  }
  .login-out-btn {
    margin-right: 1rem;
    border-radius: 0px;
    width: 100px;
    border: 1px solid $neutral-color-1;
    background-color: #7fb3bf;
  }
  .custom-navbar {
    position: sticky;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 980px) {
  .link-text {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1450px) {
  .badge {
    font-size: 1.5rem;
    height: 30px;
    border-radius: 25px;
  }
  .nav-link {
    font-size: 1.2rem;
  }
  .login-out-btn {
    font-size: 1.5rem;
  }
}
