@import '../../../../../Styles/theme.scss';
@import '../../../../../Styles/variables.scss';

.settings-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  padding: 5px;
  border-radius: 10px;
}

@media screen and (min-width: $small) {
  .settings-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #bdbdbd;
    padding: 10px;
  }
}
