@import '../../../../Styles/variables.scss';

.footer-container {
  background-color: $primary-color-1;
  bottom: -950px;
  width: 100%;
}

.footer-section-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0% 7%;
}

.copyright-container {
  background-color: $primary-color-1;
  width: 100%;
  margin-top: -40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.copyright-text-container {
  text-align: center;
  color: $neutral-color-1;
  max-width: 400px;
}

.footer-links-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

@media screen and (min-width: $small) {
  .footer-links-wrapper {
    flex-direction: row;
  }
  .footer-section-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 1000px) {
  .footer-section-wrapper {
    flex-direction: row;
  }
  .footer-links-wrapper {
    width: 50%;
    margin-top: 0px;
  }
}
