@import '../../../Styles/variables.scss';
@import '../../../Styles/theme.scss';

.history-card {
  border: 0.5px solid #828282;
  padding: 10px;
  margin-bottom: 30px;
  position: relative;
}

.history-check-row {
  margin-bottom: 10px;
}

.history-card-title {
  margin-bottom: 10px;
}

.history-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0% 5%;
}

.history-card-dropdown-container {
  display: flex;
  justify-content: center;
}

.history-column {
  width: 100%;
}

.history-dropdown-input {
  background-color: #f2f2f2;
  border-radius: 3px;
  width: 100%;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
}

.other-notes {
  height: 150px;
  width: 80%;
  background-color: #edfdff;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 40px;
}

@media screen and (min-width: $medium) {
  .history-card-container {
    flex-direction: row;
    padding: 0% 10%;
  }
  .history-column {
    width: 50%;
  }

  .history-dropdown-input {
    width: 50%;
  }
}
