@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.login-input {
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
}

.login-form {
  position: absolute;
  top: 10%;
  left: 4%;
  width: 80%;
  text-align: left;
}

#banner-image {
  min-width: 100%;
  max-width: 100%;
  min-height: 500px;
  object-fit: cover;
}

.control-dots {
  text-align: left !important;
}

.header-text {
  font-weight: bold;
  font-family: Raleway-Bold;
  font-size: 10vw;
  margin-bottom: 0;
}

.login-form-container {
  position: relative;
  justify-content: flex-end;
  height: 100vh;
}

.login-options {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: $small) {
  .header-text {
    font-size: 4vw;
  }
  #banner-image {
    margin-top: 0px;
    min-width: 0px;
    margin-left: 0px;
    max-height: 100%;
  }
  .login-form {
    position: absolute;
    top: 15%;
    left: 10%;
    width: 35%;
    text-align: left;
  }
  .login-form-container {
    margin-top: 0px;
    display: flex;
  }
}
