@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.comprehension-paragraph {
  font-family: 'Raleway';
  margin-bottom: 10px;
  max-width: 100%;
}

.resource-detail-container {
  margin: 80px 6% 30% 6%;
}

.comprehension-container {
  display: flex;
  margin-bottom: 50px;
  flex-direction: column;
}

.cholesterol-box {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.text-container {
  padding-left: 10px;
}




.section-title {
  text-align: center;
}



@media screen and (min-width: $medium) {
  .comprehension-paragraph {
    max-width: 40%;
  }
  .resource-detail-container {
    padding-left: 100px;
    padding-top: 50px;
    margin-bottom: 100;
    margin-top: 0px;
  }

  .comprehension-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
  }
  .cholesterol-box {
    margin-left: 0px;
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .text-container {
    padding-left: 0px;
  }


  .section-title {
    text-align: unset;
  }
}
