@import '../../../Styles/theme.scss';
@import '../../../Styles/variables.scss';

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 103, 127, 0.04) !important;
}

.survey-banner {
  background-color: $primary-color-1;
  color: $neutral-color-1;
  text-align: center;
  font-family: 'Raleway-Semi-Bold';
  margin-bottom: 25px;
  padding: 5px 5px;
  border-radius: 3px;
  font-size: 16px;
}

.table-cell {
  border-bottom: 0px !important;
  vertical-align: middle !important;
  font-family: 'Raleway-Semi-Bold' !important;
  border-top: 0px !important;
}

.phq-button-row {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.survey-title {
  font-family: 'Raleway';
  font-size: 18px;
}

@media screen and (min-width: $small) {
  .phq-button-row {
    flex-direction: row;
  }
  .survey-banner {
    font-size: 25px;
  }
  .survey-title {
    font-size: 25px;
  }
}
