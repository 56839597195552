@import '../../../../Styles/theme.scss';
@import '../../../../Styles/variables.scss';

.survey-row-cell {
  width: 25%;
  display: flex;
  justify-content: center;
}

.survey-row {
  padding: 10px 0px;
  flex-direction: column !important;
}

.survey-question-column {
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (min-width: $small) {
  .survey-row {
    flex-direction: row !important;
  }
  .survey-question-column {
    width: 30%;
    margin-bottom: 0px;
  }
}
