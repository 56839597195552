@import '../../../Styles/theme.scss';
@import '../../../Styles/variables.scss';

.radio-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

input {
  outline: none;
}

.hbi-input {
  background-color: #f2f2f2 !important;
  box-shadow: inset 0px 1px 3px;
  border: none;
  border-radius: 3px;
}

.hbi-input:focus {
  background-color: white !important;
  border: 0px;
  box-shadow: 0px 0px 0px 0.2rem rgba(0, 123, 255, 0.25);
}

@media screen and (min-width: $small) {
  .radio-row {
    height: 50px;
    margin-bottom: 10px;
  }
}
