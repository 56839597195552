@import '../../../Styles/variables.scss';

.sc-form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5px;
}

.sc-column {
  width: 100%;
  margin-bottom: 10px;
}

.sc-checkbox-row {
  margin-bottom: 10px;
}

@media screen and (min-width: $small) {
  .sc-form-row {
    flex-direction: row;
    margin-bottom: 10px;
  }
  .sc-column {
    width: 30%;
  }
}
