@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.confirm-page-container {
  font-family: 'Raleway';
  text-align: center;
  min-height: 700px;
  margin-top: 80px;
  padding: 10px 15px 0px 15px;
}

.success-image {
  max-height: 400px;
}

@media screen and (min-width: $small) {
  .success-image {
    max-height: 500px;
  }
  .confirm-page-container {
    margin-top: 25px;
  }
}
