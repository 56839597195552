@import '../../../Styles/variables.scss';

.physician-input:focus {
  outline: none;
  box-shadow: inset 0px 1px 3px;
}

.physician-card-group {
  display: flex;
  flex-direction: column;
}

.physician-card {
  border: 0.5px solid #828282;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
}

.physician-input {
  border: 2px solid transparent;
  border-radius: 4px;
}

@media screen and (min-width: $medium) {
  .physician-card-group {
    flex-direction: row;
  }
  .physician-card {
    padding: 40px;
  }
}
