@import '../../../../Styles/theme.scss';
@import '../../../../Styles/variables.scss';

$small: 650px;
$medium: 900px;


.caregiver-delete-modal-container {
  position: fixed;
  z-index: 1000;
  width: 50%;
  height: fit-content;
  transform: translate(-50%, -200%);
}

.caregiver-add-button {
  background-color: #00677f;
  height: 40px;
  width: 45%;
  border-radius: 4px;
  border: 0px;
}

.caregiver-cancel-button {
  height: 40px;
  width: 45%;
  border-radius: 4px;
  border: 0px;
}

.dialog-class {
  min-width: 60%;
  padding: 0px;
  @media (max-width: 500px) {
    width: 100%;
    margin: 0px;
    }
}



@media screen and (min-width: $small) {
  .caregiver-modal-container {
    max-width: 75%;
    inset: 25% auto 40px 50%;
  }
  .caregiver-delete-modal-container {
    max-width: 50%;
    inset: 25% auto 40px 50%;
  }
}
