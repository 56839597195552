@import '../../../../../Styles/theme.scss';
@import '../../../../../Styles/variables.scss';

.grid-card-container {
  height: 100px;
  min-width: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  margin-bottom: 25px;
  position: relative;
}

.grid-card-column {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
}
