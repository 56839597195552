@import '../../../Styles/variables.scss';
@import '../../../Styles/theme.scss';

.resource-card {
  height: 200px;
  width: 100%;
  margin: 11px;
  background-color: #f2f2f2;
  border: 1px solid #828282;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media screen and (min-width: $small) {
  .resource-card {
    width: 300px;
  }
}
