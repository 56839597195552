@import '../../../../Styles/variables.scss';

.intake-medication-card {
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
  position: relative;
}

.intake-med-input {
  width: 100%;
}

.frequency-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.intake-med-field-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.intake-med-field-row-2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.med-input-spacer {
  display: none;
}

.dosage-label {
  margin-left: 2px;
  width: fit-content;
}

.tradeName-dropdown {
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-top: 49px;
  z-index: 1000;
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  background-color: #edfdff;
  width: 100%;
}
.tradeName-dropdown-option {
  justify-content: center;
  min-height: 50px;
  border: 0px;
  white-space: initial;
}

.tradeName-dropdown-option-text {
  padding: 20px 10px;
  cursor: pointer;
  white-space: initial;
  width: 100%;
}
.tradeName-dropdown-option-text:hover {
  background-color: #fff;
}
@media screen and (min-width: $medium) {
  .intake-medication-card {
    padding: 40px;
  }
  .frequency-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .intake-med-field-row {
    flex-direction: row;
  }
  .intake-med-field-row-2 {
    flex-direction: row;
    align-items: center;
  }
  .med-input-spacer {
    min-width: 15px;
    display: block;
  }

  .dosage-label {
    margin-left: 5px;
  }
  .intake-med-input {
    width: 290px;
  }
  .tradeName-dropdown {
    border-radius: 3px;
    box-shadow: inset 0px 1px 3px;
    margin-top: 49px;
    z-index: 1000;
    max-height: 300px;
    width: 290px;
    overflow-y: scroll;
    position: absolute;
    background-color: #edfdff;
    text-align: left;
  }
  .tradeName-dropdown-option {
    justify-content: center;
    min-height: 50px;
    border: 0px;
    white-space: initial;
  }

  .tradeName-dropdown-option-text {
    padding: 20px 10px;
    cursor: pointer;
    white-space: initial;
    width: 100%;
  }
  .tradeName-dropdown-option-text:hover {
    background-color: #fff;
  }
}
