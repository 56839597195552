@import '../../../../Styles/variables.scss';

.container {
  width: 95%;
  text-align: center;
  min-height: 600px;
}

.checkmark {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.id-header {
  text-align: center;
  font-family: Raleway-Bold;
  font-size: 30px;
}

.id-input {
  max-width: 90%;
  text-align: left;
  margin-bottom: 0px;
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
  margin-bottom: 15px;
}

.skip-button {
  font-family: Raleway-Semi-Bold;
  background-color: #425563;
  border: 0px;
  border-radius: 1px;
  width: 65%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.skip-button:hover {
  background-color: #00677f;
}
.skip-button:disabled {
  background-color: #4f747d;
}

.next-button {
  font-family: Raleway-Semi-Bold;
  background-color: #00677f;
  border: 0px;
  border-radius: 1px;
  width: 65%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.next-button:hover {
  background-color: #00677f;
}
.next-button:focus {
  background-color: #00677f !important;
  box-shadow: none;
}
.next-button:active {
  background-color: #00677f !important;
}
.next-button:disabled {
  background-color: #4f747d;
}

.id-form-exit {
  transform: translate3d(0, 0, 0);
}
.id-form-exit-active {
  transform: translate3d(-200%, 0, 0);
  //Apply all transitions
  transition: transform 2s;
}

@media screen and (min-width: $small) {
  .id-header {
    font-size: 50px;
  }

  .container {
    left: 10%;
    width: 80%;
    text-align: center;
    min-height: 600px;
  }

  .id-input {
    max-width: 40%;
  }

  .skip-button {
    width: 25%;
  }

  .next-button {
    width: 25%;
  }
}
