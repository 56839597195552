@import '../../../../../Styles/theme.scss';
@import '../../../../../Styles/variables.scss';

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.edit-reading-row {
  display: flex;
  flex-direction: column;

  align-items: 'center';
  margin-bottom: 10px;
}

@media screen and (min-width: $small) {
  .edit-reading-row {
    flex-direction: row;
  }
}
