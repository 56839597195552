@import '../../../Styles/theme.scss';
@import '../../../Styles/variables.scss';

.scale-chart-container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 25px;
  min-width: 85vw;
}

.scale-line-chart-card {
  position: relative;
  justify-content: center;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
}

@media screen and (min-width: $medium) {
  .scale-line-chart-card {
    width: 100%;
  }
}
