@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.hr-div {
  border-bottom: 1px solid $primary-color-1;
}

.management-page-container {
  margin: 0% 0% 10% 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.program-info {
  width: 100%;
  margin-bottom: 10%;
}

.program-info-description {
  width: 90%;
}

.management-page-signup {
  width: 90%;

  margin: 0% 5% 0% 5%;
}

.management-register-button {
  justify-content: center;
  display: flex;
}

.react-player > div {
  position: absolute;
}

@media screen and (min-width: $medium) {
  .management-page-container {
    margin: 5% 10% 10% 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .program-info {
    width: 60%;
  }

  .management-page-signup {
    width: 35%;
  }

  .management-register-button {
    justify-content: left;
  }
}
