@import '../../../../Styles/variables.scss';
@import '../../../../Styles/theme.scss';

.option-button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (min-width: $medium) {
  .option-button-group {
    display: flex;
    flex-direction: row;
  }
}
