@import '../../Styles/theme.scss';
@import '../../Styles/variables.scss';

.high-glucose-page-container {
  margin: 0% 5% 0% 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.glucose-video-column {
  width: 100%;
  margin: 0%;
}

.add-caregiver-image {
  margin-bottom: 15px;
  cursor: pointer;
}

@media screen and (min-width: $medium) {
  .high-glucose-page-container {
    margin: 0% 0% 0% 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .glucose-video-column {
    width: 35%;
    margin: 0% 5% 0% 5%;
  }
}
