@import '../../../Styles/theme.scss';
@import '../../../Styles/variables.scss';

.custom-date-container {
  display: flex;
  flex-direction: column;
  min-height: 175px;
}

.start-date-picker {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid gray;
  //max-width: fit-content;
  padding-left: 10px;
  color: #495057;
}

.pie-chart-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.dashboard-page-container {
  margin-bottom: 200px;
  margin-left: 4%;
  margin-right: 4%;
}

.charts-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 600px;
}

.dashboard-filter-row {
  margin-bottom: 10px;
  height: 40px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.date-filter-select {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  color: black;
  border: 1px solid #bdbdbd;
}

.date-filter-select-container {
  width: 100%;
  height: 45px;
}

.MuiDataGrid-columnSeparator {
  visibility: hidden;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: white;
  color: $neutral-color-1;
  font-family: 'Raleway-Semi-Bold';
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: $primary-color-1 !important;
}

.MuiDataGrid-root {
  border: none !important;

}

.MuiSvgIcon-root {
  color: $neutral-color-1;
}

.MuiPaginationItem-icon {
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  width: 100%;
  text-align: center;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  text-overflow: initial;
  overflow-x: auto;
}
.MuiDataGrid-cell {
  text-align: center !important;
}

.MuiPagination-root {
  margin-left: auto;
}

.grid-template {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  grid-auto-rows: 100px;
}

.dashboard-resources-section {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.text-settings-column {
  justify-content: 'space-between';
  flex-wrap: 'wrap';
  width: 100%;
  margin-bottom: 35px;
}

.resources-column {
  width: 100%;
}


.line-chart-card {
  position: relative;
  justify-content: center;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
}

.dashboard-reset-button {
  background-color: #00677f;
  height: 100%;
  width: 40px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.edit-cell {
  justify-content: center !important;
  cursor: pointer;
}

.MuiDataGrid-cell:focus {
  outline: transparent !important;
}

.MuiDataGrid-cell:focus-within {
  outline: transparent !important;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #f2f2f2;
}

.resource-card-container {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: $medium) {
  .start-date-picker {
    width: fit-content;
  }
  .resource-card-container {
    display: flex;
    flex-direction: row;
  }
  .dashboard-page-container {
    margin-bottom: 200px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
  }
  .grid-template {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  .pie-chart-card {
    width: 28%;
    margin-bottom: 0px;
  }

  .MuiDataGrid-columnHeaderWrapper {
    background-color: $primary-color-1;
    color: $neutral-color-1;
    font-family: 'Raleway-Semi-Bold';
  }

  .MuiPaginationItem-icon {
    color: $primary-color-1;
  }

  .text-settings-column {
    width: 45%;
  }

  .resources-column {
    width: 53%;
  }

  .charts-container {
    flex-direction: row;
  }
  .dashboard-filter-row {
    width: 40%;
    flex-direction: row;
    justify-content: flex-start;
  }

  .line-chart-card {
    width: 70%;
  }
  .date-filter-select {
    min-width: 200px;
  }
  .date-filter-select-container {
    width: fit-content;
  }
  .custom-date-container {
    flex-direction: row;
  }
}
