/* Screen Sizes */
$small: 650px;
$medium: 900px;

$margin-horizontal: 50px;

/* Primary Colors */
$primary-color-1: #00677f;
$primary-color-2: #61dafb;
$primary-color-3: #425563;
$primary-color-4: #8bc93b;
$primary-color-5: #e40046;
$primary-color-6: #7a9a01;
$primary-color-7: #232d37;
$primary-color-8: #9b2743;
$primary-color-9: #eaaa00;

$status-color-1: #b2062e; // error

/* Neutral Colors */
$neutral-color-1: #ffffff;
$neutral-color-2: #bcc1c5;
$neutral-color-3: #000000;

:export {
  primary: $primary-color-1;
}
