@import '../../../../Styles/theme.scss';
@import '../../../../Styles/variables.scss';

.confirm-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10%;
  padding-top: 15px;
  padding-left: 8%;
  padding-right: 8%;
  width: 90%;
  max-width: 1500px;
}

.confirm-text {
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  padding-left: 9%;
  padding-right: 9%;
}

@media screen and (min-width: 1200px) {
  .confirm-container {
    flex-direction: row;
    padding: 100px 0px;
  }

  .confirm-text {
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //padding-left: 9%;
  }
  .confirm-container {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1100px) {
  .confirm-text {
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    padding-left: 12%;
    padding-right: 2%;
  }
}
