@import '../../../../../Styles/theme.scss';
@import '../../../../../Styles/variables.scss';

.footer-section-container {
  color: $neutral-color-1;
  padding-top: 5px;
  padding-bottom: 0px;
  min-width: 25%;
}

.section-content {
  margin-top: 10px;
  display: flex;
  font-family: 'Raleway';
  flex-direction: column;
  color: $neutral-color-1;
}

@media screen and (min-width: $small) {
  .footer-section-container {
    max-width: 25%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: $medium) {
}
