@import '../../../../Styles/theme.scss';
@import '../../../../Styles/variables.scss';

.table {
  margin-bottom: 0px !important;
}

.profile-physician-input {
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  border: 0px;
  width: 100%;
}

.profile-physician-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0px;
}

.profile-physician-checkbox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-physician-add-button {
  background-color: #00677f;
  height: 40px;
  width: 100%;
  border-radius: 0px;
  border: 0px;
}

@media screen and (min-width: $small) {
  .profile-physician-item {
    flex-direction: column;
    width: 350px;
    margin-right: 25px;
  }

  .profile-physician-checkbox {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .profile-physician-add-button {
    width: 200px;
  }
}
