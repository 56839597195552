@import '../../../../Styles/variables.scss';

.medcount-text {
  font-size: 25px;
  margin-bottom: 20px;
  color: $primary-color-1;
  font-family: Raleway-Semi-Bold;
}

.condition-button {
  font-family: Raleway-Bold;
  padding: 10px;
  margin: 5px;
  min-width: 200px;
  margin-bottom: 20px;
  font-size: 14px;
  flex: 0 0 100%;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.conditions-subtitle {
  font-size: 17px;
  margin-bottom: 20px;
}

@media screen and (min-width: $small) {
  .condition-button {
    flex: 0 0 48%;
  }
}

@media screen and (min-width: $medium) {
  .condition-button {
    font-size: 16px;
    flex: 0 0 23%;
  }
  .conditions-subtitle {
    font-size: 25px;
  }
}
