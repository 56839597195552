@import '../../../../Styles/theme.scss';
@import '../../../../Styles/variables.scss';

.password-reset-input {
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
  width: 100%;
}

.profile-home-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-home-input {
  width: 100%;
}

.height-weight-row {
  justify-content: space-between;
  width: 100%;
}

.password-spacer {
  min-width: 25px;
  display: none !important;
}

.profile-save-button {
  background-color: $primary-color-1;
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 0px;
  width: 100%;
  border-radius: 0px;
  margin: 50px 0px 20px 0px;
}

@media screen and (min-width: 1050px) {
  .height-weight-row {
    justify-content: 'flex-start';
    width: auto;
  }
  .password-spacer {
    display: block !important;
  }
  .password-reset-input {
    width: 250px;
  }
}

@media screen and (min-width: 1050px) {
  .profile-home-input {
    width: 250px;
  }
  .profile-home-input-container {
    width: auto;
  }
  .height-weight-row {
    justify-content: inherit;
    width: auto;
  }
  .profile-save-button {
    width: 200px;
  }
}
