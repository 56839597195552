@import '../../../../Styles/variables.scss';

.appointment-calendar {
  width: 100% !important;
}
.react-calendar__month-view__days__day--weekend {
  color: $neutral-color-3;
}
.react-calendar__month-view__weekdays__weekday {
  color: black;
}
.appointment-calendar__tile--active {
  background: $primary-color-1 !important;
}
.react-calendar__tile--now {
  background: $neutral-color-1 !important;
  color: black;
}

.calendar-form-container {
  min-width: 100%;
  height: auto;
  margin-bottom: 50px;
  min-height: 0px !important;
  padding: 0%;
  margin-top: 0px;
}

.calendar-content-container {
  display: flex;
  flex-direction: column;
}

.calendar-menu {
  background-color: $primary-color-1;
  color: $neutral-color-1;
  //min-height: 700px;
  width: 100%;
  //align-items: center;
  display: flex !important;
  flex-direction: column;
  padding: 10px;
  display: none;
}

.web-title {
  display: none;
  justify-content: center;
  text-align: center;
}

.mobile-title {
  font-size: 30px;
  text-align: center;
  font-family: 'Raleway-Bold';
  margin-bottom: 15px;
}

.appointment-text-container {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  width: 100%;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 0 !important;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: none;
  border-bottom: none;
  border-top: none;
}

.subtitle {
  font-family: Raleway;
  font-size: 24px;
  line-height: 28.18px;
  margin-bottom: 25px;
}

.times-container {
  height: 400px;
  background-color: '#00677f';
  margin-top: 10px;
  padding-right: 5px;
  overflow-y: scroll;
}

.paragraph {
  font-family: Raleway;
  font-size: 18px;
  line-height: 21.13px;
}

.earlier-text {
  font-family: 'Raleway-Bold';
  max-width: 100%;
  text-align: left;
}

.earlier-checkbox {
  min-width: 25%;
}

@media screen and (min-width: $medium) {
  .calendar-form-container {
    padding: 2%;
    height: auto;
    margin-bottom: 50px;
    min-height: 0px !important;
    margin-top: 0px;
  }

  .calendar-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .calendar-menu {
    background-color: $primary-color-1;
    color: $neutral-color-1;
    //min-height: 700px;
    width: 34%;
    //align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex !important;
    flex-direction: column;
    padding: 10px;
    display: none;
  }

  .appointment-text-container {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    width: 64%;
    padding-left: 100px !important;
    padding-right: 100px !important;
    padding-bottom: 20px;
    margin-left: 0 !important;
    border-top: 1px solid #e0e0e0;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    display: flex;
  }

  .times-container {
    height: 400px;
    background-color: '#00677f';
    //border: 1px solid white;
    margin-top: 10px;
    padding: 10px;
    overflow-y: scroll;
  }

  .web-title {
    display: block;
    font-size: 3rem;
    font-family: 'Raleway-Bold';
  }
  .mobile-title {
    display: none;
    text-align: center;
  }

  .earlier-text {
    text-align: center;
  }

  .earlier-checkbox {
    min-width: min-content;
  }
}
