@import './Styles/variables.scss';
//@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

#medchat-launcher-frame {
  z-index: 20;
}

.MuiPagination-root {
  background-color: transparent !important;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./Assets/Fonts/Raleway-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Semi-Bold';
  src: local('Raleway'), url(./Assets/Fonts/Raleway-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway'), url(./Assets/Fonts/Raleway-Bold.ttf) format('truetype');
}

.marquee {
justify-content: center;
}

.drawer {
  background-color: $primary-color-1 !important;
  min-width: 250px !important;
}

text {
  font-family: 'Raleway-Semi-Bold';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#medchat-cancel {
  bottom: 75px;
  right: 15px;
}

.medchat-cancel-hidden {
  display: none !important;
}

@media screen and (min-width: 765px) {
  #medchat-cancel {
    bottom: 90px;
    right: 20px;
  }
}
