@import '../../Styles/variables.scss';

.intake-container {
  margin-bottom: 100px;
  margin-top: 40px;
}

.intake-form-container {
  text-align: center;
  min-height: 400px;
  margin: auto;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intake-header {
  font-size: 7vw;
  font-weight: 600;
}

.intake-content {
  flex-grow: 2;
}

.option-button {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid lightgray !important;
}

.option-button:hover {
  cursor: pointer;
}

.option-button-selected {
  background-color: $primary-color-1;
  color: $neutral-color-1;
}

.intake-checkbox {
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  height: 25px;
  width: 25px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intake-checkbox:hover {
  cursor: pointer;
}

.intake-check {
  background-color: #00677f;
  height: 60%;
  width: 60%;
  margin-left: 0 auto;
  margin-right: 0 auto;
}

.intake-cancel-box {
  height: 25px;
  width: 25px;
  background-color: $primary-color-1;
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;
}

.intake-cancel-form {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: $primary-color-1;
}

.back-button {
  background-color: #425563;
  padding: 10px 0px;
  margin-right: 10px;
  width: 150px;
  border-radius: 4px;
}

.next-button {
  background-color: #00677f;
  padding: 10px 0px;
  margin-left: 10px;
  width: 150px;
  border-radius: 4px;
}

.save-button {
  background-color: #0092bc;
  padding: 10px 0px;
  width: 100%;
  border-radius: 4px;
}

.save-button:active {
  background-color: #0092bc !important;
}

.intake-error {
  color: #e40046;
  font-family: 'Raleway';
}

.intake-selected {
  background-color: #00677f;
  color: white !important;
}

.base-button-row {
  justify-content: space-between;
}

.intake-allergy-card {
  border: 0.5px solid #828282;
  padding: 10px;
  margin-bottom: 30px;
  position: relative;
}

@media screen and (min-width: $medium) {
  .intake-container {
  }
  .intake-header {
    font-size: 3vw;
  }
  .intake-form-container {
    max-width: 60%;
  }
  .save-button {
    width: 150px;
  }

  .intake-cancel-box {
    height: 35px;
    width: 35px;
  }
  .intake-cancel-form {
    height: 35px;
    width: 35px;
  }
  .option-button {
    width: 45%;
    margin-bottom: 0px;
  }
  .base-button-row {
    justify-content: center;
  }
  .intake-allergy-card {
    padding: 40px;
  }
}
