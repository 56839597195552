@import '../../../../Styles/theme.scss';
@import '../../../../Styles/variables.scss';

.profile-home-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-home-input {
  width: 100%;
}

.height-weight-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.height-weight-spacer {
  min-width: 25px;
  display: none;
}

.profile-save-button {
  background-color: $primary-color-1;
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 0px;
  width: 100%;
  border-radius: 4px;
  margin: 50px 0px 20px 0px;
}

@media screen and (min-width: 500px) {
  .height-weight-row {
    justify-content: 'flex-start';
    width: auto;
    flex-direction: row;
  }
  .height-weight-spacer {
    display: block;
  }
}

@media screen and (min-width: 1050px) {
  .profile-home-input {
    width: 250px;
  }
  .profile-home-input-container {
    width: auto;
  }
  .height-weight-row {
    justify-content: inherit;
    width: auto;
  }
  .profile-save-button {
    width: 200px;
  }
}
