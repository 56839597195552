@import '../../../Styles/variables.scss';

.consent-container {
  margin: 85px 2% 20% 2%;
}

.consent-error {
  color: #e40046;
  font-family: 'Raleway';
}

.consent-button {
  background-color: $primary-color-1;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 95%;
}

.signature-input {
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
  width: 80%;
  border: 2px solid transparent;
  border-radius: 4;
  text-align: center;
}

@media screen and (min-width: $small) {
  .consent-container {
    margin: 2% 5%;
  }
  .consent-button {
    width: 30%;
  }
}
