@import 'Styles/theme.scss';
@import 'Styles/variables.scss';

.careplan-container {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 100px;
  font-family: 'Raleway';
}

@media screen and (min-width: $medium) {
  .careplan-container {
    padding-right: 50px;
    padding-bottom: 100px;
    font-family: 'Raleway';
  }
}
